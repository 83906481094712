import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, ButtonGroup, Spinner, Table } from "react-bootstrap";
import { errorNotification, successNotification } from "../utils/notification";
import useCustomersData from '../store/CustomersStore';
import apiService from "../service/api.service";
import Swal from "sweetalert2";
import useEditUserData from "../store/EditUserStore";
import timeHelper from "../utils/timeHelper"
import Select, { components } from "react-select";
import { Link } from "react-router-dom";


export default function CustomersTable({
  options,
  show,
  isRenewData,
  setIsRenewData
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [groups, setGroups] = useState([]);

  const selectRef = useRef(null); // react-select bileşenine bir referans oluştur

  const handleGroupChange = async (selectedOption) => {
    if (selectedOption.value === 'no-group') {
      console.log("grupsuz seçildi")
      try {
        await apiService.post(`user/delete-group`, {
          customer_id: isEditing
        });

        successNotification("\n Müşteri grubu başarıyla silindi.")
        setIsRenewData(true)

      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await apiService.post(`user/set-group`, {
          customer_id: isEditing,
          group_id: selectedOption.value
        });

        console.log(response);
        successNotification("\n Müşteri grubu başarıyla tanımlandı.")
        const getData = await apiService.getByQuery("user");
        setIsRenewData(true)


      } catch (error) {
        console.log(error);
      }
    }
    // Grubu güncelleme işlemini burada yap
    // Örnek: await updateGroupForCustomer(currentEditingItemId, selectedOption.value);,
    console.log(isEditing, selectedOption.value)
    setIsEditing(null); // Düzenleme modundan çık
  };

  // Dış tıklama algılayıcısı
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsEditing(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);


  const setEditUser = useEditUserData((state) => state.setSelectedData);
  const zs_setCustomersData = useCustomersData((state) => state.setSelectedData);
  const zs_getCustomersData = useCustomersData((state) => state.selectedData);

  const setVerifiedUser = async (uuid) => {
    try {
      const acceptModal = await Swal.fire({
        title: 'Kullanıcı onaylanacaktır',
        text: "Bu işlem sonunda doğrulamasız kullanıcı sisteme erişebilecektir.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Vazgeç',
        confirmButtonText: 'Evet, kullanıcıyı onaylamak istiyorum!'
      });
      if (acceptModal.isConfirmed) {
        try {
          await apiService.put("user/update", uuid, { is_verified: true });
          // burada isteği at ve tamamla
          Swal.fire(
            'Başarılı!',
            'Kullanıcı başarıyla onaylanmıştır.',
            'success'
          )
          const getData = await apiService.getByQuery("user");
          zs_setCustomersData(getData.data.data);
          return;
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Bir hata oluştu...',
            text: 'Something went wrong!',
          })
        }
      }
    } catch (err) {
      errorNotification("Bir hata oluştu.");
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 20, // Yüksekliği ayarla
      minHeight: 30,
      padding: '0 1px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 30,
      padding: '0 6px'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 30,
    }),
  };

  useEffect(() => {
    // Grupları API'den al
    const fetchGroups = async () => {
      try {
        const response = await apiService.get('groups/only-groups');
        setGroups(response.data);
      } catch (error) {
        console.error("Grupları alırken bir hata oluştu:", error);
      }
    };

    fetchGroups();
  }, []);


  return (
    <>
      {isRenewData && <Spinner animation="border" role="status" style={{ zIndex: 99, position: 'absolute', top: "45%", left: "45%" }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>}
      <Table
        hover
        bordered={options && options.bordered}
        borderless={false}
        size={options && options.size}
        // hover={options && options.hover}
        className="mb-0"
        style={isRenewData ? { filter: "blur(5px)", pointerEvents: "none", position: 'relative', transition: '0.2s all', zIndex: 10 } : { filter: "blur(0px)", transition: '0.2s all' }}
      >

        <thead>
          <tr>
            <th>Adı ve Soyadı - <span className="text-danger ">E-Posta</span></th>
            <th>Grubu</th>
            <th>Alan Kodu - Telefon </th>
            <th>Bildirim </th>
            <th>Olş Tarihi </th>
            <th>Güncl. Tarihi </th>
          </tr>
        </thead>
        <tbody  >
          {zs_getCustomersData.map((item, index) => (
            <tr style={{
              verticalAlign: "middle",
            }}
              className={
                item.is_verified ? "table-light " : "table-danger "
              }

              key={index}

            >
              <td>
                <Link to={`/customers/detail/${item.id}`} variant="light" size="sm" onClick={() => setEditUser(item)}>
                  {item.fullname} {item.is_verified ? <span className="text-success"><i class="ri-checkbox-circle-fill"></i></span> :
                    <span className="text-danger"><i class="ri-question-line"></i></span>} <br /> <small><strong>{item.email} </strong></small>
                </Link>
              </td>
              <td>
                {isEditing === item.id ? (
                  <div ref={selectRef}>
                    <Select
                      styles={customStyles}
                      value={selectedGroup}
                      onChange={handleGroupChange}
                      options={[
                        { value: 'no-group', label: 'Grubu Kaldır' }, // Grupsuz seçeneği
                        ...groups
                          .filter(group => group.id !== item?.group?.id)
                          .map(group => ({ value: group.id, label: group.title }))
                      ]}
                    />
                  </div>
                ) : (
                  <div
                    style={{ cursor: 'pointer' }}
                    className="d-flex justify-content-around align-items"
                    onClick={() => setIsEditing(item.id)}
                  >
                    {item.group ? <Badge bg="dark">{item.group.title}</Badge> : <div style={{ textDecoration: '2px solid underline red' }}>Seçiniz</div>}
                    <div style={{ marginLeft: 0 }}> <i class="ri-group-line"></i></div>

                  </div>
                )}
              </td>

              <td style={{ verticalAlign: 'middle' }}>
                +{item.country_code}<strong>{item.phone}</strong>
                {item.is_verified ? <span className="text-success"><i class="ri-checkbox-circle-fill"></i></span> : <span className="text-danger"><i class="ri-question-line"></i></span>}
              </td>

              <td>
                {item.fcm_token ? <Badge variant="success">Açık</Badge> : "Kapalı"}
              </td>
              <td>
                {timeHelper(item.createdAt)}
              </td>

              <td>
                {timeHelper(item.updatedAt)}
              </td>

              <td style={{ textAlign: "end" }}>
                <ButtonGroup aria-label="Basic example">
                  <Link to={`/customers/detail/${item.id}`} variant="light" className="btn-icon" size="sm" onClick={() => setEditUser(item)}>
                    <i className="ri-eye-line"></i>
                  </Link>
                  {item.is_verified ? <Button
                    variant={"light"}

                    size="sm"
                    disabled
                  >
                    Onaylı
                  </Button> : <Button
                    className="d-flex align-items-center"
                    variant={"success"}

                    size="sm"
                    onClick={() => setVerifiedUser(item.uuid)}
                  >
                    <div className="px-2 fw-bold">
                      ONAYLA
                    </div>
                  </Button>
                  }

                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table >
    </>
  );
}
