import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Alert, Badge, Button, Card, Col, Form, Nav, Row, Table } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import Select, { components } from "react-select";
import apiService from "../../service/api.service";
import axios from "axios";
import { errorNotification, successNotification } from "../../utils/notification";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DisallowedGroups from "../../components/Signals/DisallowedGroups";

export default function Index() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [priceList, setPriceList] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [sendNotification, setSendNotification] = useState(true);
    const [justGroups, setJustGroups] = useState(false);
    const [formValues, setFormValues] = useState({
        product_id: "",
        product_name: "",
        entry_price: "",
        tp: "",
        sl: "",
        type: "",
        notification_title: "",
        notification_body: "",
        lot: "1.0",
    });

    const [selectedPrice, setSelectedPrice] = useState(null);
    const [selectedPriceName, setSelectedPriceName] = useState(null);

    // Fiyat yüklenmesi için state oluştur
    const [priceSpesificationisLoading, setPriceSpesificationisLoading] = useState(false);


    const [exactly_currency, setExactly_currency] = useState("");


    const [emptyPriceSpesificationData, setEmptyPriceSpesificationData] = useState({
        leverage: "",
        size: "",
        exactly_currency: ""
    });



    const handleGetItemPrice = async () => {
        if (formValues.product_name === "" || formValues.product_name === undefined || formValues.product_name === null) {
            errorNotification("Lütfen ürün seçiniz.")
        }
        const getLivePrice = await axios.get(`https://socket.winexapp.com/price?parity=${formValues.product_name}`);
        if (getLivePrice.data.data) {
            setFormValues({ ...formValues, entry_price: getLivePrice.data.data.P });
        } else {
            setFormValues({ ...formValues, entry_price: "Bulunamadı!" });
        }
    }


    const getPriceList = async () => {
        try {
            setIsLoading(true);
            const result = await apiService.get("prices/by-query");
            setPriceList(result.data);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    const getPriceSpesification = async (product_id, product_name = null) => {
        try {
            setPriceSpesificationisLoading(true);
            const result = await apiService.get(`prices-spesifications/by-product/${product_id}`);
            setSelectedPrice(result);
            if (product_name) {
                setSelectedPriceName(product_name);
            }
        } catch (err) {
            console.log(err);
            setPriceSpesificationisLoading(false);
        } finally {
            setPriceSpesificationisLoading(false);
        }
    }

    const handleSubmitNewPriceSpesification = async () => {
        try {

            // Eğer boş veri varsa error notification göster.
            const emptyFields = [];
            if (emptyPriceSpesificationData.leverage === "" || emptyPriceSpesificationData.leverage === undefined) {
                emptyFields.push("Kaldıraç");
            }
            if (emptyPriceSpesificationData.size === "" || emptyPriceSpesificationData.size === undefined) {
                emptyFields.push("Sözleşme büyüklüğü");
            }
            if (exactly_currency === "" || exactly_currency === undefined) {
                emptyFields.push("Parite sonuç kuru");
            }

            if (emptyFields.length > 0) {
                errorNotification(
                    `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
                );
                return;
            }


            const generatedData = {
                product_id: formValues.product_id,
                leverage: emptyPriceSpesificationData.leverage,
                size: emptyPriceSpesificationData.size,
                exactly_currency: exactly_currency
            }


            await apiService.post("prices-spesifications/new", generatedData);
            successNotification(`\n Başarıyla ürün özellikleri güncellendi.`);
            setEmptyPriceSpesificationData({
                leverage: "",
                size: "",
                exactly_currency: ""
            });
            await getPriceSpesification(formValues.product_id);
            // setSelectedPrice(selectedPrice);
        } catch (error) {
            console.log(error);
            errorNotification(`Ürün özellikleri güncellenirken bir hata oluştu!`)
            return;
        }
    }

    const handleFilterText = (e) => {
        if (typeof e.target.value === 'string') {
            return e.target.value.toUpperCase()
        } else {
            return '';
        }

    };


    useEffect(() => {
        getPriceList();
    }, []);
    useEffect(() => {
        console.log(selectedGroups)
    }, [selectedGroups]);

    useEffect(() => {
        if (selectedPrice !== null) {
        }
    }, [selectedPrice]);

    const options = priceList.map(item => ({
        value: item.id,
        label: item.meta_currency_name,
        data: item
    }));

    const CustomOption = ({ data, innerProps }) => (
        <div {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: 10, paddingBottom: 5 }}>
            <div style={{ marginRight: 10, display: 'flex' }}>
                <img src={data.data.img_url} alt={data.data.product_name} width="20" height="20" />
                {data.data.cross_img_url && <img src={data.data.cross_img_url} alt={data.label} width="20" height="20" />}
            </div>
            <div>
                <div>
                    {data.data.product_name}
                </div>
                <div className="text-muted">
                    {data.data.title}
                </div>
            </div>
        </div>
    );

    const selectOptions = [
        {
            value: "BUY",
            label: "BUY"
        },
        {
            value: "SELL",
            label: "SELL"
        }

    ]


    const breadcrumbData = [
        {
            title: "Sinyaller",
            link: "/signals",
        },
        {
            title: "Listele",
            link: "/signals",
        },
        {
            title: "Yeni oluştur",
            link: null,
        },
    ];

    if (isLoading) {
        return "Yükleniyor";
    }


    const handleSubmit = async () => {
        try {

            let disallowedGroups = [];
            if (selectedGroups.length > 0) {
                selectedGroups.map(group => disallowedGroups.push(group.id))
            }



            if (selectedPrice && selectedPrice.data === null && !priceSpesificationisLoading)
                return errorNotification("Lütfen ürün özelliklerini doldurunuz.");


            /// Form verilerini kontrol et.
            const emptyFields = [];
            if (formValues.entry_price === "") {
                emptyFields.push("Giriş fiyatı");
            }
            if (sendNotification && formValues.notification_title === "") {
                emptyFields.push("Bildirim başlığı");
            }
            if (formValues.product_id === "") {
                emptyFields.push("Ürün");
            }
            if (formValues.type === "") {
                emptyFields.push("Ürün tipi");
            }

            if (formValues.lot === "") {
                emptyFields.push("Lot miktarı");
            }

            if (emptyFields.length > 0) {
                errorNotification(
                    `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
                );
                return;
            }

            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu sinyal yayınlanacaktır.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Vazgeç',
                confirmButtonText: 'Yayınla!'
            });
            if (acceptModal.isConfirmed) {
                const generatedData = {
                    entry_price: formValues.entry_price,
                    notification_title: formValues.notification_title,
                    product_id: formValues.product_id,
                    sl: formValues.sl,
                    tp: formValues.tp,
                    type: formValues.type.value,
                    notificate: sendNotification,
                    is_active: false,
                    lot: formValues.lot,
                    notification_body: formValues.notification_body,
                    disallowedGroups: disallowedGroups,
                    for_groups: justGroups
                }

                try {
                    await apiService.post("signals/new", generatedData);
                    successNotification(`\n Başarıyla sinyal yayınlandı`);
                    navigate('/signals', { replace: true });
                } catch (e) {
                    console.log(e);
                    errorNotification(`Sinyal paylaşılırken bir hata oluştu!`)
                }


            }
        } catch (err) {
            console.log(err);

        } finally {
            console.log("form datası hazır.")
        }
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={breadcrumbData}
                    title={"Yeni Sinyal Oluştur"}
                >

                </Beadcrumb>
                <Form onSubmit={(e) => e.preventDefault}>
                    <Row className="g-3 ">
                        <Col md="8">
                            <Card className="card-three">
                                <Card.Header>
                                    <Card.Title as="h6" className="mb-0">Yeni Sinyal Oluştur</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Form.Label >Ürün Seçiniz</Form.Label>
                                                <Select
                                                    options={options}
                                                    components={{
                                                        Option: CustomOption,
                                                    }}

                                                    onChange={async (e) => {
                                                        getPriceSpesification(e.value, e.label);
                                                        setFormValues({ ...formValues, product_id: e.value, product_name: e.label })
                                                    }}
                                                    placeholder="Ürün seçiniz"

                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Form.Label >Tip Seçiniz</Form.Label>
                                                <Select
                                                    options={selectOptions}
                                                    placeholder="BUY / SELL"
                                                    defaultValue={null}
                                                    value={formValues.type}
                                                    onChange={(selectedOption) =>
                                                        setFormValues({ ...formValues, type: selectedOption })
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3">
                                                <Form.Label htmlFor="">Giriş Fiyatı <small style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={handleGetItemPrice} >Fiyatı Çek</small></Form.Label>
                                                <Form.Control type="text" placeholder="Giriş Fiyatı" value={formValues.entry_price} onChange={(e) => {
                                                    setFormValues({ ...formValues, entry_price: e.target.value })
                                                }} />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Form.Label htmlFor="">Kar Al</Form.Label>
                                                <Form.Control type="text"
                                                    value={formValues.tp}
                                                    onChange={(e) => {
                                                        setFormValues({ ...formValues, tp: e.target.value })
                                                    }}
                                                    placeholder="Kar Al Noktası" />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Form.Label htmlFor="">Zarar Durdur</Form.Label>
                                                <Form.Control type="text"
                                                    value={formValues.sl}
                                                    onChange={(e) => {
                                                        setFormValues({ ...formValues, sl: e.target.value })
                                                    }}
                                                    placeholder="Zarar Durdur Noktası" />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Form.Label htmlFor="">Lot Miktarı</Form.Label>
                                                <Form.Select
                                                    value={formValues.lot}
                                                    onChange={(e) => {
                                                        setFormValues({ ...formValues, lot: e.target.value })
                                                    }}
                                                    placeholder="Lot Miktarı"
                                                >
                                                    {[...Array(50)].map((_, i) => (
                                                        <option key={i} value={((i + 1) * 0.1).toFixed(1)}>{((i + 1) * 0.1).toFixed(1)}</option>
                                                    ))}
                                                </Form.Select>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="card-three mt-3">
                                <Card.Header>
                                    <Card.Title as="h6" className="mb-0">Ürün Özellikleri</Card.Title>
                                </Card.Header>
                                <Card.Body>

                                    {selectedPrice === null && <Row>
                                        <Col md="12">
                                            <div className="text-center"><code>Lütfen ürün seçiniz!</code></div>
                                        </Col>

                                    </Row>}
                                    {priceSpesificationisLoading && <Row><Col md="12">Yükleniyor.</Col></Row>}
                                    {selectedPrice && selectedPrice.data === null && !priceSpesificationisLoading && <Row>
                                        <Col md="12" className="mb-3">
                                            <Alert variant={"danger"}>
                                                Fiyat özellik bilgisi bulunamadı, lütfen sinyal çıkmadan önce bu bilgileri doldurunuz.
                                            </Alert>
                                        </Col>
                                        <Col md="3">

                                            <Form.Label>
                                                Ürün
                                            </Form.Label>
                                            <input type="text" className="form-control" placeholder="Ürün Adı"
                                                readOnly
                                                disabled
                                                value={selectedPriceName}
                                                onChange={async (e) => {
                                                    setEmptyPriceSpesificationData({ ...emptyPriceSpesificationData, leverage: e.target.value })
                                                }}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Form.Label>
                                                Kaldıraç
                                            </Form.Label>
                                            <input type="number" className="form-control" placeholder="Kaldıraç (Ör. 200)"

                                                onChange={async (e) => {
                                                    setEmptyPriceSpesificationData({ ...emptyPriceSpesificationData, leverage: e.target.value })
                                                }}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Form.Label>
                                                Sözleşme Büyüklüğü
                                            </Form.Label>
                                            <input type="number" className="form-control" placeholder="Sözleşme Büyüklüğü (Ör. 100000)"

                                                onChange={async (e) => {
                                                    setEmptyPriceSpesificationData({ ...emptyPriceSpesificationData, size: e.target.value })
                                                }}

                                            />
                                        </Col>
                                        <Col md="3">
                                            <Form.Label>
                                                Parite Sonuç Kuru
                                            </Form.Label>
                                            <input type="text" className="form-control" placeholder=" Parite Sonuç Kuru"
                                                maxLength={3}

                                                onChange={(e) => {
                                                    handleFilterText(e)
                                                    setExactly_currency(e.target.value?.toUpperCase())
                                                }}

                                            />
                                            <small>Burada <span className="text-danger">TRY</span>,<span className="text-danger">USD</span> gibi değerler girebilirsiniz.</small>
                                        </Col>
                                        <Col md="12">
                                            <div className="d-flex justify-content-end">
                                                <Button onClick={() => handleSubmitNewPriceSpesification()}> Güncelle </Button>
                                            </div>
                                        </Col>
                                    </Row>}

                                    {selectedPrice && selectedPrice.data !== null && !priceSpesificationisLoading && <Row>
                                        <Col md="3">
                                            <Form.Label>
                                                Ürün
                                            </Form.Label>
                                            <input type="text" className="form-control" placeholder="Ürün Adı"
                                                readOnly
                                                disabled
                                                value={selectedPriceName}
                                                onChange={async (e) => {
                                                    setFormValues({ ...formValues, leverage: e.target.value })
                                                }}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Form.Label>
                                                Kaldıraç
                                            </Form.Label>
                                            <input type="number" className="form-control" placeholder="Kaldıraç (Ör. 200)"
                                                readOnly
                                                disabled
                                                value={selectedPrice.data.leverage}
                                                onChange={async (e) => {
                                                    setFormValues({ ...formValues, leverage: e.target.value })
                                                }}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Form.Label>
                                                Sözleşme Büyüklüğü
                                            </Form.Label>
                                            <input type="number" className="form-control" placeholder="Sözleşme Büyüklüğü (Ör. 100000)"
                                                readOnly
                                                disabled
                                                value={selectedPrice.data.size}
                                                onChange={async (e) => {
                                                    setFormValues({ ...formValues, size: e.target.value })
                                                }}

                                            />
                                        </Col>
                                        <Col md="3">
                                            <Form.Label>
                                                Parite Sonuç Kuru
                                            </Form.Label>
                                            <input type="text" className="form-control" placeholder=" Parite Sonuç Kuru"
                                                value={selectedPrice.data.exactly_currency}
                                                readOnly
                                                disabled
                                                maxLength={3}

                                                onChange={(e) => {
                                                    handleFilterText(e)
                                                    setExactly_currency(e.target.value?.toUpperCase())
                                                }}

                                            />
                                            <small>Burada <span className="text-danger">TRY</span>,<span className="text-danger">USD</span> gibi değerler girebilirsiniz.</small>
                                        </Col>
                                    </Row>}



                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="">
                                <Card.Header>
                                    <Card.Title as="h6" className="mb-0">Bildirim Ayarları / Yayın</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Form.Label htmlFor="">Bildirim Başlığı</Form.Label>
                                                <Form.Control type="text" placeholder="Bildirim başlığı"
                                                    value={formValues.notification_title}
                                                    onChange={(e) =>
                                                        setFormValues({ ...formValues, notification_title: e.target.value })
                                                    }
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Form.Label htmlFor="">Bildirim İçeriği</Form.Label>
                                                <Form.Control maxLength={65} type="text" placeholder="Bildirim içeriği 65 krktr."
                                                    value={formValues.notification_body}
                                                    onChange={(e) =>
                                                        setFormValues({ ...formValues, notification_body: e.target.value })
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Form.Check type="switch"
                                                className="mb-2"
                                                checked={sendNotification}
                                                onChange={(e) => {
                                                    setSendNotification(!sendNotification)
                                                }}
                                                label="Bildirim göndermek istiyorum" />
                                            <Form.Check type="switch"
                                                checked={justGroups}
                                                onChange={(e) => {
                                                    setJustGroups(!justGroups)
                                                }}
                                                label="Bildirimi sadece gruplara gönder (Grubu olmayan üyeler göremez)" />
                                        </div>
                                    </Col>
                                    <DisallowedGroups selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups}></DisallowedGroups>

                                </Card.Body>
                                <Card.Footer>
                                    <Button className="w-100" variant="success" onClick={handleSubmit}>Yayınla</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
                <Footer />
            </div >
        </React.Fragment >
    );
}
